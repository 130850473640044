/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is loaded on all pages.
 */

import { ready } from "@devowl-wp/cookie-consent-web-client";
import { memorizeJQueryEvent, hijackJqueryFn, hijackJqueryEach, memorizeNativeEvent } from "@devowl-wp/headless-content-unblocker";
import { overwriteElementorVideoApi, hijackElementorOnInit, listenOptInForContentBlocker } from "./others";
const hijackJqueryFns = ["fitVids", "mediaelementplayer", "prettyPhoto", "gMap", "wVideo", "wMaps", "wMapsWithPreload", "wGmaps", "WLmaps", "WLmapsWithPreload", "aviaVideoApi", {
  fn: "magnificPopup",
  customBlocked: async element => {
    const src = element.getAttribute("src") || element.getAttribute("href");
    const {
      unblock,
      unblockSync
    } = window.consentApi;
    if (unblockSync(src)) {
      return unblock(src, {
        ref: element,
        confirm: true
      });
    }
  }
}];
const hijackQueryEach = [
// https://www.famethemes.com/themes/onepress/
".onepress-map",
// HivePress
'div[data-component="map"]',
// Sober theme
".sober-map"];
listenOptInForContentBlocker();
hijackJqueryFn(hijackJqueryFns);
hijackJqueryEach(hijackQueryEach);
overwriteElementorVideoApi();
hijackElementorOnInit([{
  className: "Video",
  optIn: ($element, _ref) => {
    let {
      gotClicked
    } = _ref;
    if (gotClicked) {
      const settings = $element.data("settings");
      settings.autoplay = true;
      $element.data("settings", settings);
    }
  }
}, {
  className: "VideoPlaylistHandler",
  // Delay execution to avoid layout trashing (tab content does not get full height for embedded video)
  delay: 1000
}]);
ready(() => {
  // jQuery fn cannot be ready yet, let's wait for DOM ready
  hijackJqueryFn(hijackJqueryFns);
  hijackJqueryEach(hijackQueryEach);
  memorizeNativeEvent(window, "elementor/frontend/init");
  memorizeJQueryEvent(document, document, "tve-dash.load"); // Thrive Leads
  memorizeJQueryEvent(document, document, "mylisting/single:tab-switched"); // My Listing theme
  memorizeJQueryEvent(document, document, "hivepress:init"); // HivePress
}, "interactive");