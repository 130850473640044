import { extendBlockerBodyStylesheet } from "@devowl-wp/web-cookie-banner";
import { useState, useMemo } from "react";
import { useBlockerStylesheet, useA11yId } from "../../..";
import { Cookie, useBlocker, useCommonTeachings } from "../../..";
import { BodyDescription, Button } from "../../common";
const BlockerBody = () => {
  const {
    Container,
    CookieScroll,
    a11yIds: {
      firstButton
    }
  } = useBlockerStylesheet().extend(...extendBlockerBodyStylesheet);
  const blocker = useBlocker();
  const a11yCookieList = useA11yId();
  const [areCookiesVisible, setCookiesVisible] = useState(false);
  const {
    texts: {
      blockerLoadButton,
      blockerLinkShowMissing,
      blockerAcceptInfo
    },
    blocker: {
      services
    },
    consent,
    groups,
    onUnblock,
    productionNotice,
    isConsentRecord,
    i18n: {
      close
    }
  } = blocker;

  // Calculate missing cookies
  const missing = useMemo(() => {
    const missing = [];
    const allConsentCookies = [];
    for (const cookies of Object.values(consent.groups)) {
      allConsentCookies.push(...cookies);
    }
    for (const {
      items
    } of groups) {
      for (const cookie of items) {
        // Cookie is needed for this blocker? Yes, check if already consent...
        if (services.indexOf(cookie.id) > -1 && allConsentCookies.indexOf(cookie.id) === -1) {
          missing.push(cookie);
        }
      }
    }
    return missing;
  }, [groups, services, consent]);
  const {
    description,
    teachings
  } = useCommonTeachings({
    services: missing,
    // Not needed in blocker, as there is already a link with "Show all services which I need to consent to"
    disableListServicesNotice: true
  });
  return h(Container, null, h("div", null, h(BodyDescription, {
    description: description,
    teachings: [...teachings, blockerAcceptInfo],
    nl2br: true
  }), h(Button, {
    id: firstButton,
    type: "acceptIndividual",
    onClick: () => setCookiesVisible(!areCookiesVisible),
    forceShow: true,
    busyOnClick: false,
    "aria-expanded": areCookiesVisible,
    "aria-controls": a11yCookieList
  }, areCookiesVisible ? close : blockerLinkShowMissing), h(CookieScroll, {
    "aria-expanded": areCookiesVisible
  }, areCookiesVisible && missing.map(cookie => h(Cookie, {
    key: cookie.id,
    cookie: cookie,
    checked: true,
    disabled: true
  }))), h(Button, {
    type: "acceptAll",
    onClick: e => onUnblock(e),
    forceShow: true,
    sticky: true,
    busyOnClick: !isConsentRecord
  }, blockerLoadButton), productionNotice));
};
export { BlockerBody };